// Libraries
import React from 'react';
// Assets
import * as styles from './footer.module.css';
// Constants
import { SITE_NAME } from '../utils/constants';

export default class SubscribeForm extends React.Component {
  handleSubmit = async (e) => {
    e.preventDefault();
    window.open(
      `https://tinyletter.com/the-citadel`,
      'popupwindow',
      'scrollbars=yes,width=800,height=600'
    );
    return true;
  };

  render() {
    return (
      <form
        name="subscribeForm"
        method="POST"
        id="subscribe-form"
        className={styles.subscribe}
        onSubmit={this.handleSubmit}
      >
        <button type="submit">
          Subscribe to <b>{SITE_NAME}</b>
        </button>
      </form>
    );
  }
}
