// Libraries
import React from 'react';
import { Link } from 'gatsby';
// Components
import SubscribeForm from './subscribe-form';
// Styles
import * as styles from './footer.module.css';
// Utils
import { LEGAL, ABOUT, LINKS } from '../utils/routeConstants';

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.linksContainer}>
        {/* <Link to={ABOUT} activeClassName="active" className={styles.footerLink}>
        About
      </Link>
      <Link to={LINKS} activeClassName="active" className={styles.footerLink}>
        Links
      </Link> */}
        <Link to={LEGAL} activeClassName="active" className={styles.footerLink}>
          Legal
        </Link>
        <SubscribeForm />
      </div>
    </div>
  );
};

export default Footer;
